import styled from '@emotion/styled';

const HotelCardStyle = styled('div')`
    .iconHotelorGolf {
        color: #12b897;
    }
    .iconDetail {
        color: #12b897;
        margin-right: 10px;
    }
    .discriptRoundOfGofl {
        display: flex;
        margin-bottom: 10px;
        width: 100%;
        justify-content: space-around;
        align-items: center;
    }
    .iconDetailGolf {
        visibility: hidden;
    }
    .hotelAndGoflView {
        border-radius: 4px;
        background: #fff;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        padding: 24px;
        display: flex;
        justify-content: space-between;
        margin-top: 20x;
        margin-bottom: 27x;
    }
    .imgHotelOrGofl {
        width: 171px;
        height: 171px;
        gap: 16px;
        border-radius: 6px;
        margin-right: 24px;
    }
    .viewInforHotel {
        display: flex;
        width: 85%;
    }
`;

export default HotelCardStyle;
