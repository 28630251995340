import {
    faCircleUser,
    faFlag,
    faGolfBallTee,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import icons from 'src/assets/icon';
import images from 'src/assets/image';
import ModalComponent from 'src/components/modalCustom';
import ModalTouristDetination from '../ModalTouristDestination';
import IntineraryStandardStyle from './style';
import moment from 'moment';
import HotelCardComponent from 'src/components/hotelCard';
import FlightCardComponent from 'src/components/flightCard';
import CarHideCardComponent from 'src/components/carHideCard';
import TransferCard from 'src/components/tranferCard';
import GolfsCardComponent from 'src/components/golfsCard';

interface intineraryProps {
    dataItinerary?: any;
    dateNumber?: any;
}

const IntineraryStandardComponent: React.FC<intineraryProps> = ({
    dataItinerary,
    dateNumber,
}) => {
    const date = dataItinerary?.date || null;
    const accommodations = dataItinerary?.accommodations || null;
    const flight = dataItinerary?.flight || null;
    const carHire = dataItinerary?.carHire || null;
    const transfers = dataItinerary?.transfers || null;
    const golf = dataItinerary?.golfs || null;

    return (
        <IntineraryStandardStyle>
            <div className="titleDay1">
                Day {dateNumber}: {moment(date).format('ddd Do MMMM YYYY')}
            </div>
            {accommodations?.length > 0 && (
                <div>
                    {accommodations?.map((item: any, index: any) => {
                        return (
                            <HotelCardComponent dataHotel={item} key={index} />
                        );
                    })}
                </div>
            )}

            {golf?.length > 0 && (
                <div>
                    {golf?.map((item: any, index: any) => {
                        return (
                            <GolfsCardComponent dataGolf={item} key={index} />
                        );
                    })}
                </div>
            )}
            {flight && (
                <div>
                    <FlightCardComponent dataFlight={flight} />
                </div>
            )}
            {carHire && (
                <div>
                    <CarHideCardComponent dataCarHide={carHire} />
                </div>
            )}
            {transfers?.length > 0 && (
                <div>
                    {transfers?.map((item: any, index: any) => {
                        return <TransferCard dataTransfer={item} key={index} />;
                    })}
                </div>
            )}
        </IntineraryStandardStyle>
    );
};

export default React.memo(IntineraryStandardComponent);
