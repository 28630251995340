import styled from '@emotion/styled';

const GolfsCardStyle = styled('div')`
    .iconHotelorGolf {
        color: #12b897;
    }
    .iconDetail {
        color: #12b897;
        margin-right: 10px;
    }
    .discriptRoundOfGofl {
        display: flex;
        margin-bottom: 10px;
        width: 100%;
        align-items: center;
    }
    .timeOfRound{
        margin-left: 10px;
    }
    .detailRoundGolf{
        margin-left: 20px;
    }
    .iconDetailGolf {
        visibility: hidden;
    }
    .endIcon{
        margin-left: 5px;
    }
    .hotelAndGoflView {
        border-radius: 4px;
        background: #fff;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        padding: 24px;
        display: flex;
        justify-content: space-between;
        margin-top: 20x;
        margin-bottom: 27x;
    }
    .imgHotelOrGofl {
        width: 171px;
        height: 171px;
        gap: 16px;
        border-radius: 6px;
        margin-right: 24px;
    }
    .viewInforGolf {
        display: flex;
        width: 85%;
    }
`;

export default GolfsCardStyle;
