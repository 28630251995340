import styled from '@emotion/styled';

const IntineraryStandardStyle = styled('div')`
    .iconHotelorGolf {
        color: #12b897;
    }
    .iconDetail {
        color: #12b897;
    }
`;

export default IntineraryStandardStyle;
