import React, { useState } from 'react';
import GolfsCardStyle from './style';
import images from 'src/assets/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFlag,
    faGolfBallTee,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import ModalComponent from '../modalCustom';
import ModalTouristDetination from 'src/pages/QuoteDetailPage/ItineraryInDetail/ModalTouristDestination';
import moment from 'moment';

interface GolfsCardProps {
    dataGolf?: any;
}

const GolfsCardComponent: React.FC<GolfsCardProps> = ({ dataGolf }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    console.log(dataGolf, 'dataGolf');

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const nameOfGolf = dataGolf?.name || '';
    const addressGolf =
        `${dataGolf?.address?.companyName},${' '}${dataGolf?.address?.town}` ||
        '';
    const numRound = dataGolf?.rounds || '';
    const detailRound = dataGolf?.detailRound || [];
    const totalPassengers = detailRound.reduce(
        (total: any, round: any) => total + round.numOfPassenger,
        0,
    );

    return (
        <GolfsCardStyle>
            <div className="hotelAndGoflView">
                <div className="viewInforGolf">
                    <img
                        alt=""
                        className="imgHotelOrGofl"
                        src={images.hotelImage}
                    />
                    <div>
                        <div className="titleItem">
                            Round of Golf at {nameOfGolf}{' '}
                        </div>
                        <div className="addressHotelOrGolf">{addressGolf}</div>
                        <div className="contentInfor">
                            {numRound} Round at {nameOfGolf} for{' '}
                            {totalPassengers} golfers
                        </div>
                        {detailRound?.map((item: any, index: any) => {
                            return (
                                <div
                                    className="discriptRoundOfGofl"
                                    key={index}
                                >
                                    <div>
                                        <FontAwesomeIcon
                                            className="iconDetail"
                                            icon={faFlag}
                                        />
                                    </div>
                                    <div className="timeOfRound">
                                        {moment
                                            .utc(item?.time)
                                            .format('HH:mm') === '00:00'
                                            ? 'BTC'
                                            : moment
                                                  .utc(item?.time)
                                                  .format('HH:mm')}{' '}
                                        on{' '}
                                        {moment
                                            .utc(item?.time)
                                            .format('DD/MM/YYYY')}
                                    </div>
                                    <div className="detailRoundGolf">
                                        {' '}
                                        x{item?.numOfPassenger}
                                        <FontAwesomeIcon
                                            className="iconDetail endIcon"
                                            icon={faUser}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        {/* <div className="discriptRoundOfGofl">
                            <div>
                                <FontAwesomeIcon
                                    className="iconDetail"
                                    icon={faFlag}
                                />
                            </div>
                            <div>14:00 on 14/08/2024</div>
                            <div>
                                {' '}
                                x4
                                <FontAwesomeIcon
                                    className="iconDetail"
                                    icon={faUser}
                                />
                            </div>
                        </div> */}

                        {/* <div className="discriptRoundOfGofl">
                            <div>
                                <FontAwesomeIcon
                                    className="iconDetail"
                                    icon={faGolfBallTee}
                                />
                            </div>
                            <div>14:10 on 14/08/2024</div>
                            <div>
                                x2
                                <FontAwesomeIcon
                                    className="iconDetailGolf"
                                    icon={faUser}
                                />
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="iconAndBtnItem">
                    <FontAwesomeIcon
                        className="iconHotelorGolf"
                        icon={faGolfBallTee}
                    />
                    <div className="btnSeeDetail" onClick={() => showModal()}>
                        See details
                    </div>
                </div>
            </div>
            {/* <div className="reviewSeller">
                <div>
                    “I picked an earlier tee time, since you mentioned you’ve
                    got the yips. I thought you might appreciate the extra time
                    and relaxation of a quieter course!”
                </div>
                <div className="avatarSeller">
                    <FontAwesomeIcon className="avatar" icon={faCircleUser} />
                </div>
            </div> */}
            <ModalComponent
                width={525}
                visible={isModalVisible}
                onClose={handleClose}
                title="Stay at The Belfry"
                content={<ModalTouristDetination />}
            />
        </GolfsCardStyle>
    );
};

export default GolfsCardComponent;
